<template>
		<div class="w">
			<div class="my_index_c forget-password">
			<h3>{{this.$t('changeP')}}</h3>
			<div>
				<ul class="w forget-password-info">
					<li>
						<p>{{this.$t('phone')}}</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="changePwd.userMobile" type="text" :placeholder="this.$t('boundP')" /></div>
						</div>
						<div class="clear"></div>
					</li>
					<li>
						<p>{{this.$t('testCode')}}</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="changePwd.code" type="text" :placeholder="this.$t('inputCode')" /><button @click="getVerifyCode" v-if="show">{{this.$t('getCode')}}</button><button disable v-else class="button">{{count}}秒后重新获取</button></div>
						</div>
						<div class="clear"></div>
					</li>
					<li>
						<p>{{this.$t('newP')}}:</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="changePwd.userPwd" type="password" :placeholder="this.$t('newPassword')" /></div>
						</div>
						<div class="clear"></div>
					</li>
					<li>
						<p>{{this.$t('sureChange')}}</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="changePwd.userPwd1" type="password" :placeholder="this.$t('newPassword1')" /></div>
						</div>
						<div class="clear"></div>
					</li>
					<li><div class="my_index_rt_4"><input name="" type="button" :value="this.$t('sureChange')" @click="changePassword"/></div></li>
				</ul>
			</div>
		</div>
		</div>
</template>

<script>
import MySlot from '@/components/component/MySlot.vue'
import { changePwd,verifyCode } from '../../register/model'
import { showLayer } from "@/utils/com";
export default {
	data(){
		return {
			changePwd:{
				userMobile:'',
				code:'',
				userPwd:'',
				userPwd1:'',
				type:1
			},
			show:true,  
			//按钮禁用状态
				disable:true,
				count:''
		}
	},
	components:{
		MySlot
	},
	methods:{
		getVerifyCode(){
			if(this.changePwd.usermobile !== ''){
				verifyCode(this.changePwd).then(res=>{
				if(res === 'success') {
					showLayer("toast", "验证码获取成功！");
					this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
						this.count = TIME_COUNT;
						this.show = false;
						this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
				}
			})
			}
		},
		changePassword(){
			if(this.changePwd.userMobile !=='' || this.changePwd.newPassword !=='' || this.changePwd.oldPassword !==''){
				changePwd(this.changePwd).then(res =>{
				if(res === 'ok'){
					 showLayer("toast", "修改成功！");
					  window.localStorage.clear('sessionId')
					this.$router.push('/login')
				}
			})
			}
		}
	}
	
}
</script>